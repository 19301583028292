export default [
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Blue01&clotheType=Overall&eyeType=Side&eyebrowType=DefaultNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheFancy&hairColor=Red&hatColor=PastelYellow&mouthType=Eating&skinColor=Light&topType=WinterHat2',
        'firstName': 'Dean',
        'lastName': 'Baumbach',
        'role': 'Employee',
        'status': 'Active'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Prescription01&avatarStyle=Circle&clotheColor=Blue02&clotheType=ShirtVNeck&eyeType=Cry&eyebrowType=UpDownNatural&facialHairColor=Auburn&facialHairType=BeardMedium&hairColor=Blonde&hatColor=Gray01&mouthType=Grimace&skinColor=Light&topType=LongHairStraightStrand',
        'firstName': 'Cristobal',
        'lastName': 'Kris',
        'role': 'admin',
        'status': 'Pending'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Prescription01&avatarStyle=Circle&clotheColor=PastelYellow&clotheType=ShirtVNeck&eyeType=Close&eyebrowType=UpDown&facialHairColor=Auburn&facialHairType=MoustacheFancy&hairColor=Black&hatColor=Red&mouthType=Tongue&skinColor=DarkBrown&topType=LongHairBigHair',
        'firstName': 'Jordan',
        'lastName': 'Kshlerin',
        'role': 'Employee',
        'status': 'Inactive'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=White&clotheType=Overall&eyeType=Side&eyebrowType=SadConcernedNatural&facialHairColor=Black&facialHairType=BeardMagestic&hairColor=BlondeGolden&hatColor=PastelYellow&mouthType=Vomit&skinColor=Brown&topType=LongHairStraight2',
        'firstName': 'Ivah',
        'lastName': 'Cummings',
        'role': 'Employee',
        'status': 'Pending'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Wayfarers&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=GraphicShirt&eyeType=Cry&eyebrowType=Default&facialHairColor=Black&facialHairType=BeardMedium&hairColor=Blonde&hatColor=PastelBlue&mouthType=Vomit&skinColor=Black&topType=ShortHairShortWaved',
        'firstName': 'Miller',
        'lastName': 'Pollich',
        'role': 'admin',
        'status': 'Pending'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Prescription01&avatarStyle=Circle&clotheColor=Heather&clotheType=Hoodie&eyeType=Wink&eyebrowType=UpDownNatural&facialHairColor=Black&facialHairType=MoustacheFancy&hairColor=BrownDark&hatColor=Blue01&mouthType=Concerned&skinColor=Tanned&topType=LongHairMiaWallace',
        'firstName': 'Earl',
        'lastName': 'Brown',
        'role': 'Employee',
        'status': 'Active'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Wayfarers&avatarStyle=Circle&clotheColor=PastelBlue&clotheType=CollarSweater&eyeType=Wink&eyebrowType=RaisedExcitedNatural&facialHairColor=Auburn&facialHairType=MoustacheFancy&hairColor=BrownDark&hatColor=PastelOrange&mouthType=Disbelief&skinColor=Black&topType=ShortHairDreads02',
        'firstName': 'Clifford',
        'lastName': 'Marks',
        'role': 'Employee',
        'status': 'Pending'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Prescription01&avatarStyle=Circle&clotheColor=Blue01&clotheType=Overall&eyeType=EyeRoll&eyebrowType=Default&facialHairColor=Blonde&facialHairType=BeardLight&hairColor=Auburn&hatColor=Blue02&mouthType=ScreamOpen&skinColor=Tanned&topType=Turban',
        'firstName': 'Neil',
        'lastName': 'Kemmer',
        'role': 'admin',
        'status': 'Active'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Red&clotheType=Overall&eyeType=Side&eyebrowType=UpDownNatural&facialHairColor=Auburn&facialHairType=BeardLight&hairColor=Platinum&hatColor=PastelBlue&mouthType=Tongue&skinColor=Brown&topType=LongHairStraight2',
        'firstName': 'Boyd',
        'lastName': 'Leannon',
        'role': 'Employee',
        'status': 'Pending'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Wayfarers&avatarStyle=Circle&clotheColor=PastelBlue&clotheType=ShirtVNeck&eyeType=Hearts&eyebrowType=SadConcernedNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheFancy&hairColor=Black&hatColor=Pink&mouthType=Tongue&skinColor=Pale&topType=ShortHairShaggyMullet',
        'firstName': 'Monroe',
        'lastName': "O'Conner",
        'role': 'admin',
        'status': 'Inactive'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Prescription01&avatarStyle=Circle&clotheColor=Blue03&clotheType=Hoodie&eyeType=Wink&eyebrowType=FlatNatural&facialHairColor=Brown&facialHairType=BeardLight&hairColor=Brown&hatColor=PastelOrange&mouthType=ScreamOpen&skinColor=Black&topType=LongHairFro',
        'firstName': 'Sydney',
        'lastName': 'Smitham',
        'role': 'admin',
        'status': 'Inactive'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelBlue&clotheType=ShirtCrewNeck&eyeType=Close&eyebrowType=RaisedExcited&facialHairColor=Platinum&facialHairType=Blank&hairColor=SilverGray&hatColor=Blue01&mouthType=Eating&skinColor=Pale&topType=ShortHairTheCaesarSidePart',
        'firstName': 'Rocky',
        'lastName': 'Walsh',
        'role': 'admin',
        'status': 'Pending'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Round&avatarStyle=Circle&clotheColor=Heather&clotheType=Overall&eyeType=Hearts&eyebrowType=Angry&facialHairColor=Auburn&facialHairType=Blank&hairColor=BlondeGolden&hatColor=Gray02&mouthType=Default&skinColor=Light&topType=LongHairStraight',
        'firstName': 'Angus',
        'lastName': 'Jast',
        'role': 'Employee',
        'status': 'Inactive'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Prescription01&avatarStyle=Circle&clotheColor=Pink&clotheType=BlazerSweater&eyeType=Squint&eyebrowType=UpDown&facialHairColor=Brown&facialHairType=BeardMagestic&hairColor=Black&hatColor=Gray02&mouthType=ScreamOpen&skinColor=Brown&topType=LongHairShavedSides',
        'firstName': 'Enola',
        'lastName': 'Keeling',
        'role': 'admin',
        'status': 'Inactive'
    },
    {
        'avatarUrl': 'https://avataaars.io/?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=GraphicShirt&eyeType=Surprised&eyebrowType=SadConcernedNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheFancy&hairColor=Red&hatColor=Blue02&mouthType=Default&skinColor=Black&topType=ShortHairShortFlat',
        'firstName': 'Kenneth',
        'lastName': 'Hoppe',
        'role': 'Employee',
        'status': 'Inactive'
    }
];
