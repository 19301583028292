import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import randomUserList from '@/components/user/UserPreferences/Users/randomUserList';
var Users = (function (_super) {
    __extends(Users, _super);
    function Users() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userList = randomUserList;
        return _this;
    }
    Users.prototype.getStatusStyle = function (status) {
        var color;
        if (status === 'Active')
            color = '#32BCAD';
        if (status === 'Pending')
            color = '#FFC107';
        if (status === 'Inactive')
            color = '#FA0000';
        return { color: color };
    };
    Users = __decorate([
        Component
    ], Users);
    return Users;
}(Vue));
export default Users;
