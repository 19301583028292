var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Users" },
    [
      _c("h1", { staticClass: "text-h6 Users-title" }, [_vm._v("Users")]),
      _vm._m(0),
      _vm._l(_vm.userList, function(user, index) {
        return _c("div", { key: index, staticClass: "Users-list clickable" }, [
          _c("img", {
            attrs: {
              src: user.avatarUrl,
              alt: "avatar",
              width: "30px",
              height: "30px"
            }
          }),
          _c("span", { staticClass: "f-name" }, [
            _vm._v(_vm._s(user.firstName))
          ]),
          _c("span", { staticClass: "l-name" }, [
            _vm._v(_vm._s(user.lastName))
          ]),
          _c("span", { staticClass: "role" }, [_vm._v(_vm._s(user.role))]),
          _c(
            "span",
            { staticClass: "status", style: _vm.getStatusStyle(user.status) },
            [_vm._v(_vm._s(user.status))]
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Users-list-header" }, [
      _c("div", { staticClass: "checkBox" }),
      _c("span", { staticClass: "f-name" }, [_vm._v("First Name")]),
      _c("span", { staticClass: "l-name" }, [_vm._v("Last Name")]),
      _c("span", { staticClass: "role" }, [_vm._v("Role")]),
      _c("span", { staticClass: "status" }, [_vm._v("Status")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }